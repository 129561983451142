import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dayOfWeek',
})
export class DayOfWeekPipe implements PipeTransform {
  public states = {
    1: 'Lunes',
    2: 'Martes',
    3: 'Miércoles',
    4: 'Jueves',
    5: 'Viernes',
    6: 'Sábado',
    7: 'Domingo',
  };
  transform(value: string | number, ...args) {
    // This is our catch for data that hasn't interpolated
    // from its source yet, a basic async fix.
    if (value == null) {
      return;
    }
    // Otherwise, lookup the state name from the acronym
    if (this.states[value]) {
      return this.states[value];
    } else {
      return value;
    }
  }
}
