import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ticketType',
})
export class TicketTypePipe implements PipeTransform {
  transform(value: string, ...args: any[]): any {
    if (!value) {
      return '';
    }
    // value="2018-05-28 12:00:00";
    switch (value) {
      case 'payment':
        return 'Problema con cobro';
      case 'forgot':
        return 'Olvido de artículo';
      case 'invoice':
        return 'Facturación';
      case 'service':
        return 'Servicio al cliente';
      case 'products':
        return 'Productos';
      default:
        return 'Otro';
    }
  }
}
