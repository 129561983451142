<ion-content class="ion-padding">
  <ion-button (click)="closeModal()" class="close" fill="clear" color="light">
    <ion-icon slot="icon-only" name="close"></ion-icon>
  </ion-button>
  <div #square class="square"></div>
  <div class="zoom-ratio-wrapper">
    <ion-range
      [min]="minZoomRatio"
      [max]="maxZoomRatio"
      *ngIf="minZoomRatio !== undefined && maxZoomRatio !== undefined"
      (ionChange)="setZoomRatio($any($event))"
    ></ion-range>
  </div>
  <ion-fab slot="fixed" horizontal="end" vertical="bottom" *ngIf="isTorchAvailable">
    <ion-fab-button (click)="toggleTorch()" fill="clear" color="light">
      <ion-icon name="flashlight"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-content>
