import { formatDistance } from 'date-fns';
import { es } from 'date-fns/locale';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeAgo',
})
export class TimeAgoPipe implements PipeTransform {
  transform(value: Date | string | number, ...args: any[]): any {
    if (!value) return '';
    const date = new Date(value);
    //value="2018-05-28 12:00:00";
    return formatDistance(date, new Date(), { addSuffix: args[0], locale: es });
  }
}
