import { BehaviorSubject } from 'rxjs';

import { inject, Injectable, NgZone } from '@angular/core';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { StorageService, UserService } from '@core/services';
import {
  AlertController,
  IonicSafeString,
  ModalController,
  NavController,
  Platform,
  ToastController,
} from '@ionic/angular';
import { User } from '@shared/models';
import { iabOptions } from '@shared/utils';

import { RegisterSamlModalComponent } from '../login/components/register-saml-modal/register-saml-modal.component';
import { ApiService } from './api.service';
import { LoadingService } from './loading.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  #authStateSubject = new BehaviorSubject(false);
  #iab = inject(InAppBrowser);
  #ngZone: NgZone = inject(NgZone);

  constructor(
    private storage: StorageService,
    private platform: Platform,
    private api: ApiService,
    public toastCtrl: ToastController,
    private navCtrl: NavController,
    private alertCtrl: AlertController,
    private loadingService: LoadingService,
    private userService: UserService,
    private modalCtrl: ModalController
  ) {
    this.platform.ready().then(() => {});
  }

  async getUser() {
    return (await this.storage.get<User>('me')) || null;
  }

  getUserFromServer() {
    return (
      this.api
        .getData('user')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((res: any) => {
          if (res && res.success && res.user) {
            this.userService.updateUser(res.user);
            this.#checkTermsAndConditions(res.user);
            return this.storage.set('me', res.user).then(() => {
              return res.user;
            });
          }
          return null;
        })
        .catch(() => {
          this.presentToast('Error de conexión con el servidor. Intente nuevamente más tarde.');
        })
        .finally(() => {})
    );
  }

  checkRedirect() {
    this.storage.get('me').then((user) => {
      if (user) {
        this.redirectTo(user);
      } else {
        this.logout();
      }
    });
  }

  redirectTo(user) {
    if (!user) {
      this.logout();
    } else if (user.active) {
      if (user.type2 === 'guard') {
        this.navCtrl.navigateRoot(['/locatec']);
      } else if (user.type2 === 'business') {
        this.navCtrl.navigateRoot(['/tecservice-business/rewards']);
      } else if (user.type2 === 'expresotec') {
        this.navCtrl.navigateRoot(['/tecservice-expresotec/busdriver']);
      } else if (user.type2 === 'transportec-driver' && user.campus_registered > 2) {
        this.navCtrl.navigateRoot(['/tecservice-driver/stops']);
      } else if (user.campus_registered > 2) {
        this.navCtrl.navigateRoot(['/tecservice-transportec/wallet']);
      } else {
        this.navCtrl.navigateRoot(['/tecservice/home']);
      }
    } else {
      this.navCtrl.navigateRoot(['/email-confirmation']);
    }
  }

  checkRedirectFromServer() {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      const user = await this.getUser();
      if (user) {
        this.getUserFromServer()
          .then(() => {
            this.redirectTo(user);
          })
          .finally(() => {
            resolve(true);
          });
      } else {
        resolve(true);
      }
    });
  }

  async presentToast(msg = '') {
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: 3000,
      position: 'top',
    });
    return await toast.present();
  }

  switchCampus(campusId) {
    this.loadingService.present();
    return this.api
      .putData('profile/campus_current/' + campusId, {})
      .then((res) => {
        if (res && res.success && res.user) {
          return this.storage.set('me', res.user).then(() => {
            // this.eventService.publish('campus:change');
            return res.user;
          });
        }
        return null;
      })
      .finally(() => {
        this.loadingService.dismiss();
      });
  }

  async login(form) {
    this.loadingService.present();
    return this.api
      .postData('login', form)
      .then((res) => {
        if (res && res.success) {
          return this.storage.set('me', res.user).then(() => {
            return this.storage.set('api_token', res.api_token).then(() => {
              if (res.user.active) {
                if (res.user.type2 === 'guard') {
                  this.navCtrl.navigateRoot(['/locatec']);
                } else if (res.user.type2 === 'business') {
                  this.navCtrl.navigateRoot(['/tecservice-business/rewards']);
                } else if (res.user.type2 === 'expresotec') {
                  this.navCtrl.navigateRoot(['/tecservice-expresotec/busdriver']);
                } else if (res.user.type2 === 'transportec-driver' && res.user.campus_registered > 2) {
                  if (res.realtrip) {
                    this.storage.set('realtrip', res.realtrip);
                  }
                  this.navCtrl.navigateRoot(['/tecservice-driver/stops']);
                } else if (res.user.campus_registered > 2) {
                  this.navCtrl.navigateRoot(['/tecservice-transportec/wallet']);
                } else {
                  this.navCtrl.navigateRoot(['/tecservice/home']);
                }
              } else {
                this.navCtrl.navigateRoot(['/email-confirmation']);
              }
              return this.#authStateSubject.next(true);
            });
          });
        } else {
          return res.errors;
        }
      })
      .finally(() => {
        this.loadingService.dismiss();
      });
  }

  loginDriver(form) {
    this.loadingService.present();
    return this.api
      .postData('loginpin', form)
      .then((res) => {
        if (res && res.success) {
          if (res.bus) {
            this.storage.set('bus', res.bus);
          }
          if (res.routes) {
            this.storage.set('routes', res.routes);
          }
          return this.storage.set('me', res.user).then((response) => {
            return this.storage.set('api_token', res.api_token).then((response2) => {
              if (res.user.active) {
                if (res.user.type2 === 'guard') {
                  this.navCtrl.navigateRoot(['/locatec']);
                } else if (res.user.type2 === 'business') {
                  this.navCtrl.navigateRoot(['/tecservice-business/rewards']);
                } else if (res.user.type2 === 'expresotec') {
                  this.navCtrl.navigateRoot(['/tecservice-expresotec/busdriver']);
                } else if (res.user.type2 === 'transportec-driver' && res.user.campus_registered > 2) {
                  if (res.realtrip) {
                    this.storage.set('realtrip', res.realtrip);
                  }
                  this.navCtrl.navigateRoot(['/tecservice-driver/stops']);
                } else if (res.user.campus_registered > 2) {
                  this.navCtrl.navigateRoot(['/tecservice-transportec/wallet']);
                } else {
                  this.navCtrl.navigateRoot(['/tecservice/home']);
                }
              } else {
                this.navCtrl.navigateRoot(['/email-confirmation']);
              }
              return this.#authStateSubject.next(true);
            });
          });
        } else {
          return res.message;
        }
      })
      .finally(() => {
        this.loadingService.dismiss();
      });
  }

  register(form) {
    this.loadingService.present();
    return this.api
      .postData('' + form.endpoint, form)
      .then((res) => {
        if (res && res.success) {
          return this.storage.set('me', res.user).then((response) => {
            return this.storage.set('api_token', res.api_token).then((response2) => {
              // this.router.navigate(['/tecservice/home']);
              if (res.user.active) {
                if (res.user.type2 === 'business') {
                  // this.eventService.publish("user:business");
                } else if (res.user.campus_registered > 2) {
                  this.navCtrl.navigateRoot(['/tecservice-transportec/wallet']);
                } else {
                  // this.eventService.publish("user:login");
                  this.navCtrl.navigateRoot(['/tecservice/home']);
                }
              } else {
                this.navCtrl.navigateRoot(['/email-confirmation']);
              }
              return this.#authStateSubject.next(true);
            });
          });
        } else {
          return res.errors;
        }
      })
      .finally(() => {
        this.loadingService.dismiss();
      });
  }

  update(form) {
    this.loadingService.present();
    return this.api
      .putData('user', form)
      .then((res) => {
        if (res && res.success) {
          return this.storage.set('me', res.user).then(() => {
            this.#authStateSubject.next(true);
            return res.user;
          });
        } else {
          return res.errors;
        }
      })
      .finally(() => {
        this.loadingService.dismiss();
      });
  }

  logout() {
    this.storage.remove('me').then(() => {
      this.storage.remove('api_token').then(() => {
        this.navCtrl.navigateRoot(['/']);
        this.#authStateSubject.next(false);
      });
    });
    this.storage.remove('subscription');
    this.storage.remove('realtrip');
  }

  resendMail() {
    this.loadingService.present('Reenviando correo...');
    this.api
      .postData('resend/email', {})
      .then(
        (res) => {
          this.presentToast('Correo enviado exitosamente.');
        },
        (err) => {
          this.presentToast('Error de conexión con el servidor. Intente nuevamente más tarde.');
        }
      )
      .finally(() => {
        this.loadingService.dismiss();
      });
  }

  forgotMail(form) {
    this.loadingService.present('Enviando correo...');
    this.api
      .postData('forgot', form)
      .then(
        (res) => {
          this.presentToast('Correo enviado exitosamente.');
        },
        (err) => {
          this.presentToast('Error de conexión con el servidor. Intente nuevamente más tarde.');
        }
      )
      .finally(() => {
        this.loadingService.dismiss();
      });
  }

  async isAuthenticated() {
    const user = (await this.storage.get('me')) || null;
    if (user) {
      return true;
    } else {
      return false;
    }
  }

  async hasMealplan() {
    const user = (await this.storage.get<User>('me')) || null;
    if (user && user.borrego === 1) {
      return true;
    } else {
      return false;
    }
  }

  async canExpresotec() {
    const user = (await this.storage.get<User>('me')) || null;
    if (
      user &&
      typeof user.campus_registered !== 'undefined' &&
      user.campus_registered === 1 &&
      user.campus_current === 1 &&
      (user.type === 'Estudiante' || user.type === 'Representativo' || user.type === 'Colaborador')
    ) {
      return true;
    } else {
      return false;
    }
  }

  async getMembership() {
    return this.api
      .getData('membership')
      .then((res) => {
        if (res && res.subscription) {
          return true;
        } else {
          return false;
        }
      })
      .catch(() => {
        return false;
      });
  }

  async mayExpresotec() {
    const user = (await this.storage.get<User>('me')) || null;
    if (
      user &&
      typeof user.campus_registered !== 'undefined' &&
      user.campus_registered === 1 &&
      !user.expresotec &&
      user.campus_current === 1 &&
      (user.type === 'Estudiante' || user.type === 'Representativo' || user.type === 'Colaborador')
    ) {
      return this.api
        .getData('membership')
        .then((res) => {
          if (res && res.school_period) {
            return true;
          } else {
            return false;
          }
        })
        .catch(() => {
          return false;
        });
    }
    return false;
  }

  async canTransportec() {
    const user = (await this.storage.get<User>('me')) || null;
    if (
      user &&
      typeof user.campus_registered !== 'undefined' &&
      user.campus_registered > 2 &&
      user.campus_current > 2 &&
      (user.type === 'Estudiante' || user.type === 'Representativo' || user.type === 'Colaborador')
    ) {
      return true;
    } else {
      return false;
    }
  }

  async isBusiness() {
    const user = (await this.storage.get<User>('me')) || null;
    if (user.type2 === 'business') {
      return true;
    } else {
      return false;
    }
  }

  async isGuard() {
    const user = (await this.storage.get<User>('me')) || null;
    if (user.type2 === 'guard') {
      return true;
    } else {
      return false;
    }
  }

  async isExpresotec() {
    const user = (await this.storage.get<User>('me')) || null;
    if (user.type2 === 'expresotec') {
      return true;
    } else {
      return false;
    }
  }

  async getBusiness() {
    const user = (await this.storage.get<User>('me')) || null;
    if (user.type2 === 'business') {
      let business = await this.storage.get('business');
      if (!business) {
        business = user.business[0];
      }
      if (typeof business === 'string') {
        try {
          business = JSON.parse(business);
        } catch (e) {
          console.error(e);
        }
      }
      await this.storage.set('business', business);
      return business;
    } else {
      this.logout();
      return false;
    }
  }

  async askExit() {
    const alert = await this.alertCtrl.create({
      header: 'Cerrar TecService',
      message: '¿Esta seguro que quiere salir de la aplicación?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          handler: () => {
            console.log('Application exit prevented!');
          },
        },
        {
          text: 'Cerrar App',
          handler: () => {
            navigator['app'].exitApp();
          },
        },
      ],
    });
    alert.present();
  }

  getBalance() {
    return this.api
      .getData('balance')
      .then((res) => {
        if (res) {
          return res;
        }
      })
      .catch((err) => {
        this.presentToast('Error de conexión con el servidor. Intente nuevamente más tarde.');
      })
      .finally(() => {});
  }

  async getSubscription_() {
    const subscription = (await this.storage.get('subscription')) || null;
    if (subscription) {
      return subscription;
    } else {
      return this.api
        .getData('suscription')
        .then((res) => {
          if (res) {
            this.storage.set('subscription', res.data);
            return res.data;
          }
        })
        .catch((err) => {
          this.presentToast('Error de conexión con el servidor. Intente nuevamente más tarde.');
        })
        .finally(() => {});
    }
  }

  async getSubscription() {
    return this.api
      .getData('suscription')
      .then((res) => {
        if (!res || !res.success) {
          return;
        }
        this.storage.set('subscription', res.data);
        return res.data;
      })
      .catch((err) => {
        this.presentToast('Error de conexión con el servidor. Intente nuevamente más tarde.');
      })
      .finally(() => {});
  }

  get(type) {
    return this.api
      .getData('' + type)
      .then((res) => {
        if (res) {
          return res;
        }
      })
      .catch((err) => {
        this.presentToast('Error de conexión con el servidor. Intente nuevamente más tarde.');
      })
      .finally(() => {});
  }

  async singleLogOut() {
    try {
      const isSsoLogin = await this.storage.get('ssologin');
      if (!isSsoLogin) {
        this.logout();
        return;
      }
      this.loadingService.present();

      const sloRedirect = await this.api.postData('logout', {});
      this.loadingService.dismiss();
      const ssoUrl = sloRedirect.redirect_to;
      if (!ssoUrl) {
        this.logout();
        return;
      }
      const iab = this.#iab.create(ssoUrl, '_blank', iabOptions);
      iab.on('loadstart').subscribe((event) => {
        const response: boolean = event.url.includes(`saml2/success`);

        if (response) {
          this.#ngZone.run(() => {
            this.logout();
          });
          iab.close();
        }
      });
    } catch (error) {
      this.loadingService.dismiss();
    }
  }

  async singleSignOn() {
    try {
      this.loadingService.present();

      const ssoRedirect = await this.api.postData('saml2/tec', {});
      this.loadingService.dismiss();
      const ssoUrl = ssoRedirect.redirect_to;
      const iab = this.#iab.create(ssoUrl, '_blank', iabOptions);
      iab.on('loadstart').subscribe(async (event) => {
        const response: boolean = event.url.includes(`saml2/success`);

        if (response) {
          const queryParams = this.getUrlParametersFromUrl(event.url);
          // check the action params
          if (queryParams['action'] === 'register') {
            this.openRegisterSamlModal(queryParams);
            iab.close();
            return;
          }
          // if there is a token and a user, then the user is authenticated, the token stored and the user set
          if (queryParams['token'] && queryParams['id']) {
            await this.storage.set('api_token', queryParams['token']);
            const user = {
              id: +queryParams['id'],
              name: queryParams['name'],
              lastname: queryParams['lastname'],
              fullname: queryParams['fullname'],
              roster: queryParams['roster'],
              email: queryParams['email'],
              role: queryParams['role'],
              enrollment: queryParams['enrollment'],
              active: +queryParams['active'],
              campus_registered: +queryParams['campus_registered'],
              campus_current: +queryParams['campus_current'],
              type: queryParams['type'],
              type2: queryParams['type2'],
              status: queryParams['status'],
              campus_registered_name: queryParams['campus_registered_name'],
              user_type_id: +queryParams['user_type_id'],
            };
            await this.storage.set('me', user);
            await this.storage.set('ssologin', true);
            this.#authStateSubject.next(true);
            this.#ngZone.run(() => {
              this.navCtrl.navigateRoot(['/tecservice-transportec/wallet']);
              iab.close();
            });
          }
        }
      });
    } catch (error) {
      this.loadingService.dismiss();
    }
  }

  private async presentTermsAndConditions(queryParams) {
    const alert = await this.alertCtrl.create({
      header: 'Términos y condiciones',
      message: new IonicSafeString(
        `Para poder continuar, es necesario que aceptes los <a href="https://tecservice.app/terminosycondiciones?campus=${queryParams['campus_registered']}" target="_blank">términos y condiciones</a>.`
      ),
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          handler: () => {
            this.logout();
          },
        },
        {
          text: 'Aceptar',
          handler: () => {
            this.openRegisterSamlModal(queryParams);
          },
        },
      ],
    });
    alert.present();
  }

  private openTerms(campusId: number) {
    this.#iab.create(
      (this.platform.is('ios') ? '' : '') + 'https://tecservice.app/terminosycondiciones?campus=' + campusId,
      '_blank',
      iabOptions
    );
  }

  private async openRegisterSamlModal(user: Partial<User>, mode = 'register') {
    const openedModal = await this.modalCtrl.getTop();
    if (openedModal) {
      return;
    }

    const modal = await this.modalCtrl.create({
      component: RegisterSamlModalComponent,
      componentProps: { user, mode },
    });

    modal.onDidDismiss().then((response) => {
      if (response?.data && response?.data?.api_token && response?.data?.user) {
        this.storage.set('api_token', response.data.api_token);
        this.storage.set('me', response.data.user);
        this.#authStateSubject.next(true);
        this.#ngZone.run(() => {
          this.navCtrl.navigateRoot(['/tecservice-transportec/wallet']);
        });
      }
    });

    return await modal.present();
  }

  private getUrlParametersFromUrl(url: string): Record<string, string> {
    const queryString = url.split('?')[1];
    const urlParams = new URLSearchParams(queryString);
    const params: Record<string, string> = {};
    urlParams.forEach((value, key) => {
      params[key] = value;
    });
    return params;
  }

  #checkTermsAndConditions(user: User) {
    if (user && !user.terms) {
      this.openRegisterSamlModal(user, 'update');
    }
  }
}
