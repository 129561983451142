import { Pipe, PipeTransform } from '@angular/core';
import { Platform } from '@ionic/angular';

@Pipe({
  name: 'toDatestring',
})
export class ToDateStringPipe implements PipeTransform {
  constructor(public platform: Platform) {}

  transform(value: any, args: string): any {
    if (value) {
      if (!args || args === 'date') {
        return this.formatJustDate(value);
      } else {
        return this.formatJustHours(value);
      }
    } else {
      return null;
    }
  }

  formatDate(dateString: string): string {
    const arr: string[] = dateString.split(/[- :T]/);
    return (
      arr[2] +
      '/' +
      arr[1] +
      '/' +
      arr[0] +
      ' ' +
      (arr[3] || '') +
      (arr[4] ? ':' + arr[4] : '') +
      (arr[5] ? ':' + arr[5] : '')
    );
  }

  formatJustDate(dateString: string): string {
    const arr: string[] = dateString.split(/[- :T]/);
    return arr[2] + '/' + arr[1] + '/' + arr[0];
  }

  formatJustHours(dateString: string): string {
    const arr: string[] = dateString.split(/[- :T]/);
    if (arr.length > 4) {
      return arr[3] + ':' + arr[4];
    } else {
      return 'NA';
    }
  }
}
