import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { ApiResponse, PayCodeCard, QueryConfig, Ticket } from '@shared/models';

import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class BusticketService {
  constructor(private apiService: ApiService) {}

  getBusticketsNext(config: QueryConfig): Observable<ApiResponse<Ticket[]>> {
    return this.apiService.get<ApiResponse<Ticket[]>>('bustickets/next', config);
  }

  getBusticketsPrevious(config: QueryConfig): Observable<ApiResponse<Ticket[]>> {
    return this.apiService.get<ApiResponse<Ticket[]>>('bustickets/previous', config);
  }

  create(
    ticket: Partial<Ticket> & {
      bus_id?: number;
      realtrip_id?: number;
      stop_id?: number;
      token_conekta?: string;
      token_openpay?: string;
      token_payu?: string;
      device_session_id?: string;
      card?: PayCodeCard;
    }
  ): Observable<ApiResponse<Ticket>> {
    return this.apiService.post<Ticket>('bustickets', ticket);
  }
}
