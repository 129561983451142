import { Pipe, PipeTransform } from '@angular/core';
import { Platform } from '@ionic/angular';

@Pipe({
  name: 'toDateObj',
})
export class ToDateObjPipe implements PipeTransform {
  constructor(public platform: Platform) {}

  transform(value: any): any {
    if (value) {
      // const temp = value.toString().replace(' ', 'T'); // + '-06:00';
      const temp = value.toString().replace('T', ' ').replace(/-/g, '/').substr(0, 19); // + '-06:00';
      const tzoffset = new Date().getTimezoneOffset() * 60000; // offset in milliseconds
      let returnDate;
      if (this.platform.is('ios')) {
        returnDate = new Date(temp).getTime() + tzoffset;
      } else {
        // returnDate = new Date(temp).getTime();
        returnDate = this.getIOSSaveDateObj(value);
      }
      // console.log({value});
      // console.log({returnDate});
      // console.log({returnDate});
      return returnDate;
    } else {
      return null;
    }
  }

  getIOSSaveDateObj(dateString: string) {
    let arr: string[] = dateString.split(/[- :]/);
    let date;
    if (dateString.indexOf('-') > 0) {
      date = new Date(+arr[0], +arr[1] - 1, +arr[2] || 0, +arr[3] || 23, +arr[4] || 0, +arr[5] || 0);
    } else {
      arr = dateString.split(/[. :]/);
      date = new Date(+arr[2], +arr[1] - 1, +arr[0], +arr[3] || 23, +arr[4] || 0, +arr[5] || 0);
    }
    return date;
  }
}
