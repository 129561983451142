import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingController, ToastController } from '@ionic/angular';
import { ApiResponse } from '@shared/models';

import { EventService } from './event.service';

const API_BASE_URL = environment.apiBaseUrl;

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  loading: HTMLIonLoadingElement;

  constructor(
    private http: HttpClient,
    private loadingCtrl: LoadingController,
    public toastCtrl: ToastController,
    private eventService: EventService
  ) {}

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log({ error });
      console.error(`Backend returned code ${error.status}, ` + `body was: `, error.error);

      // Unauthorized
      if (error.status >= 400) {
        // if 401 Unauthorized = logout
        if (error.status === 401) {
          console.log('Publishing user:logout');
          this.eventService.publish('user:logout');
          // this.auth.logout();
        }
      }
    }
    return { data: null, error: 'Something bad happened; please try again later.' };
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }

  get<T>(url: string): Observable<ApiResponse<T>> {
    return this.http.get<ApiResponse<T>>(API_BASE_URL + url);
  }

  getData(url: string): Promise<any> {
    return this.get(url)
      .toPromise()
      .then((res) => res)
      .catch((err) => this.handleError(err));
  }

  postData(url: string, body: any): Promise<any> {
    return this.http
      .post(API_BASE_URL + url, body)
      .toPromise()
      .then((res) => res)
      .catch((err) => this.handleError(err));
  }

  putData(url: string, body: any): Promise<any> {
    return this.http
      .put(API_BASE_URL + url, body)
      .toPromise()
      .then((res) => res)
      .catch((err) => this.handleError(err));
  }

  deleteData(url: string): Promise<any> {
    return this.http
      .delete(API_BASE_URL + url)
      .toPromise()
      .then((res) => res)
      .catch((err) => this.handleError(err));
  }

  async presentLoading(message = 'Cargando...') {
    this.loading = await this.loadingCtrl.create({
      message,
    });
    return await this.loading.present();
  }

  async presentToast(message = '') {
    const toast = await this.toastCtrl.create({
      message,
      duration: 3000,
      position: 'top',
    });
    return await toast.present();
  }

  async dismissLoading() {
    return await setTimeout(() => {
      return this.loading.dismiss();
    }, 100);
  }

  isBase64(text: string): boolean {
    const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
    return base64regex.test(text);
  }
}
