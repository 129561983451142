import { Component, ElementRef, inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Coordinates } from '@awesome-cordova-plugins/geolocation/ngx';
import { BarcodeFormat, BarcodeScanner, LensFacing, StartScanOptions } from '@capacitor-mlkit/barcode-scanning';
import { Capacitor } from '@capacitor/core';
import { EncryptionService } from '@core/services';
import { InputCustomEvent, ModalController } from '@ionic/angular';
import { isBase64 } from '@shared/utils';

export type ScannedResponse = { coords?: Coordinates; code: string };
const TIMEOUT_WAIT = 0;

@Component({
  selector: 'app-scanning-modal',
  templateUrl: './scanning-modal.component.html',
  styleUrls: ['./scanning-modal.component.scss'],
})
export class ScanningModalComponent implements OnInit, OnDestroy {
  #encryptionService: EncryptionService = inject(EncryptionService);
  protected secureKey = '17749937251153819552391078335260'; // Any string, the length should be 32
  #modalController: ModalController = inject(ModalController);
  @Input()
  public formats: BarcodeFormat[] = [BarcodeFormat.QrCode];
  @Input()
  public lensFacing: LensFacing = LensFacing.Back;

  @ViewChild('square')
  public squareElement: ElementRef<HTMLDivElement> | undefined;

  public isTorchAvailable = false;
  public minZoomRatio: number | undefined;
  public maxZoomRatio: number | undefined;

  ngOnInit(): void {
    if (Capacitor.isNativePlatform()) {
      BarcodeScanner.isTorchAvailable().then((result) => {
        this.isTorchAvailable = result.available;
      });
    }
  }

  ionViewDidEnter(): void {
    setTimeout(() => {
      this.startScan();
    }, TIMEOUT_WAIT);
  }

  ionViewDidLeave() {
    // Show everything behind the modal again
    document.querySelector('body')?.classList.remove('scanning-active');
    this.stopScan();
  }

  public ngOnDestroy(): void {
    document.querySelector('body')?.classList.remove('scanning-active');
    this.stopScan();
  }

  public setZoomRatio(event: InputCustomEvent): void {
    if (!event.detail.value) {
      return;
    }
    BarcodeScanner.setZoomRatio({
      zoomRatio: parseInt(event.detail.value as any, 10),
    });
  }

  public async closeModal(response?: ScannedResponse): Promise<void> {
    setTimeout(() => {
      this.#modalController.dismiss(response);
    }, TIMEOUT_WAIT);
  }

  public async toggleTorch(): Promise<void> {
    await BarcodeScanner.toggleTorch();
  }

  private async startScan(): Promise<void> {
    // Hide everything behind the modal (see `src/theme/variables.scss`)
    document.querySelector('body')?.classList.add('scanning-active');

    if (!Capacitor.isNativePlatform()) {
      console.log('Scanning is only available on native platforms, closing modal...');
      //   this.closeModal();
      return;
    }

    const options: StartScanOptions = {
      formats: this.formats,
      lensFacing: this.lensFacing,
    };

    const squareElementBoundingClientRect = this.squareElement?.nativeElement.getBoundingClientRect();
    const scaledRect = squareElementBoundingClientRect
      ? {
          left: squareElementBoundingClientRect.left * window.devicePixelRatio,
          right: squareElementBoundingClientRect.right * window.devicePixelRatio,
          top: squareElementBoundingClientRect.top * window.devicePixelRatio,
          bottom: squareElementBoundingClientRect.bottom * window.devicePixelRatio,
          width: squareElementBoundingClientRect.width * window.devicePixelRatio,
          height: squareElementBoundingClientRect.height * window.devicePixelRatio,
        }
      : undefined;
    const detectionCornerPoints = scaledRect
      ? [
          [scaledRect.left, scaledRect.top],
          [scaledRect.left + scaledRect.width, scaledRect.top],
          [scaledRect.left + scaledRect.width, scaledRect.top + scaledRect.height],
          [scaledRect.left, scaledRect.top + scaledRect.height],
        ]
      : undefined;
    const listener = await BarcodeScanner.addListener('barcodeScanned', async (event) => {
      //   this.#ngZone.run(() => {
      //     const cornerPoints = event.barcode.cornerPoints;
      //     if (detectionCornerPoints && cornerPoints) {
      //       if (
      //         detectionCornerPoints[0][0] > cornerPoints[0][0] ||
      //         detectionCornerPoints[0][1] > cornerPoints[0][1] ||
      //         detectionCornerPoints[1][0] < cornerPoints[1][0] ||
      //         detectionCornerPoints[1][1] > cornerPoints[1][1] ||
      //         detectionCornerPoints[2][0] < cornerPoints[2][0] ||
      //         detectionCornerPoints[2][1] < cornerPoints[2][1] ||
      //         detectionCornerPoints[3][0] > cornerPoints[3][0] ||
      //         detectionCornerPoints[3][1] < cornerPoints[3][1]
      //       ) {
      //         return;
      //       }
      //     }
      await listener.remove();
      const scannedResponse: ScannedResponse = {
        code: this.decrypt(event.barcode.displayValue),
      };
      this.closeModal(scannedResponse);
      //   });
    });
    await BarcodeScanner.startScan(options);
    void BarcodeScanner.getMinZoomRatio().then((result) => {
      this.minZoomRatio = result.zoomRatio;
    });
    void BarcodeScanner.getMaxZoomRatio().then((result) => {
      this.maxZoomRatio = result.zoomRatio;
    });
  }

  private async stopScan(): Promise<void> {
    if (Capacitor.isNativePlatform()) {
      try {
        document.querySelector('body')?.classList.remove('scanning-active');
        await BarcodeScanner.removeAllListeners();
        await BarcodeScanner.stopScan();
      } catch (error) {
        console.error('Error stopping scan: ', error);
      }
    }
  }

  private decrypt(code): string {
    try {
      if (isBase64(code)) {
        // Decrypt base64
        const decryptedCode = this.#encryptionService.decrypt(code, this.secureKey);
        return decryptedCode;
      }
      return code;
    } catch (error) {
      return '';
    }
  }
}
