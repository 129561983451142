import { Point, Position } from 'geojson';

import { distance, lineString, nearestPointOnLine, point } from '@turf/turf';

/**
 * Returns the nearest point on a polygon or the point itself if it is within the limit.
 *
 * @param lineCoords -  line(s) coords to snap to
 * @param pointCoords - point to snap from
 * @param limit - distance limit to return either the nearest point or the point itself
 * @returns
 *
 * @example
 * var coords = [
 *   [-77.031669, 38.878605],
 *   [-77.029609, 38.881946],
 *   [-77.020339, 38.884084],
 *   [-77.025661, 38.885821],
 *   [-77.021884, 38.889563],
 *   [-77.019824, 38.892368]
 * ];
 * var position= [-77.037076, 38.884017];
 *
 * var snapped = nearestPointOnLineOrPoint(coords, position, 0.5);
 *
 */
export function nearestPointOnLineOrPoint(lineCoords: Position[], pointCoords: Position, limit = 1): Position {
  const nearestPoint = nearestPointOnLines(lineCoords, pointCoords);
  const distance = nearestPoint.properties.dist;

  return distance <= limit ? nearestPoint.geometry.coordinates : pointCoords;
}

/**
 * Returns the nearest point on a polygon.
 *
 * @param lineCoords -  line(s) coords to snap to
 * @param pointCoords - point to snap from
 * @returns
 *
 * @example
 * var coords = [
 *   [-77.031669, 38.878605],
 *   [-77.029609, 38.881946],
 *   [-77.020339, 38.884084],
 *   [-77.025661, 38.885821],
 *   [-77.021884, 38.889563],
 *   [-77.019824, 38.892368]
 * ];
 * var position= [-77.037076, 38.884017];
 *
 * var snapped = nearestPointOnLines(coords, position);
 *
 */
export function nearestPointOnLines(lineCoords: Position[], pointCoords: Position) {
  const lns = lineString(lineCoords);
  const pt = point(pointCoords);
  return nearestPointOnLine(lns, pt, { units: 'kilometers' });
}

/**
 * Returns the distance in kilometers between two points.
 *
 * @param point1 -  first point
 * @param point2 - second point
 * @returns
 *
 * @example
 * var point1 = [-77.031669, 38.878605];
 * var point2 = [-77.029609, 38.881946];
 *
 * var distance = calculateDistanceInKm(point1, point2);
 *
 */
export function calculateDistanceInKm(point1: Point, point2: Point): number {
  return distance(point1, point2, { units: 'kilometers' });
}
