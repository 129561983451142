<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="closeModal()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding" *ngIf="order?.charges">
  <ion-grid style="height: 100%">
    <ion-row class="ion-align-items-center ion-justify-content-center">
      <ion-icon name="checkmark" color="primary" hidden></ion-icon>
      <img src="assets/imgs/spei.jpg" *ngIf="order.charges.data[0].payment_method.type === 'spei'" />
      <img src="assets/imgs/oxxo.jpg" *ngIf="order.charges.data[0].payment_method.type === 'oxxo'" />
      <img
        src="assets/imgs/creditcard.jpg"
        *ngIf="
          order.charges.data[0].payment_method.type === 'creditcard' ||
          order.charges.data[0].payment_method.type === 'credit_card'
        "
      />
      <div *ngIf="order.charges || order.suscription_transportec_option_id">
        <p>Monto {{ amount | currency }}</p>
      </div>
      <div *ngIf="order.charges.data[0].payment_method.type === 'oxxo'">
        <img [src]="order.charges.data[0].payment_method.barcode_url" />
        <p>Referencia: {{ order.charges.data[0].payment_method.reference }}</p>
      </div>
      <div *ngIf="order.charges.data[0].payment_method.type === 'spei'">
        <p>Banco: STP (Sistema de Transferencias y Pagos)</p>
        <p>CLABE: {{ order.charges.data[0].payment_method.clabe }}</p>
      </div>
      <p
        *ngIf="
          order.charges.data[0].payment_method.type === 'spei' || order.charges.data[0].payment_method.type === 'oxxo'
        "
      >
        Expira: {{ order.charges.data[0].payment_method.expires_at * 1000 | date: 'dd/MM/yyyy' }}
      </p>
      <div *ngIf="method === 'wallet'">
        <p>Método de pago: Wallet</p>
      </div>
      <p *ngIf="order.payment_status === 'paid' || order.status === 1">
        Estado:
        <strong>Pagada</strong>
      </p>
      <ion-button shape="round" color="primary" expand="block" (click)="closeModal()">OK</ion-button>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-content class="ion-padding" *ngIf="order?.authorization || method === 'credit_card_paycode'">
  <ion-grid style="height: 100%">
    <ion-row class="ion-align-items-center ion-justify-content-center">
      <img src="assets/imgs/creditcard.jpg" />
      <div>
        <p>Monto {{ amount | currency }}</p>
      </div>
      <p>
        Estado:
        <strong>Pagada</strong>
      </p>
      <ion-button shape="round" color="primary" expand="block" (click)="closeModal()">OK</ion-button>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-content class="ion-padding" *ngIf="method === 'wallet'">
  <ion-grid style="height: 100%">
    <ion-row class="ion-align-items-center ion-justify-content-center">
      <ion-icon name="checkmark" color="primary"></ion-icon>
      <div>
        <p>Monto {{ amount | currency }}</p>
      </div>
      <div *ngIf="method === 'wallet'">
        <p>Método de pago: Wallet</p>
      </div>
      <p>
        Estado:
        <strong>Pagada</strong>
      </p>
      <ion-button shape="round" color="primary" expand="block" (click)="closeModal()">OK</ion-button>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-content class="ion-padding" *ngIf="method === 'paypal'">
  <ion-grid style="height: 100%">
    <ion-row class="ion-align-items-center ion-justify-content-center">
      <ion-icon name="checkmark" color="primary"></ion-icon>
      <div>
        <p>Monto {{ amount | currency }}</p>
      </div>
      <p>Método de pago: PayPal</p>
      <p>
        Estado:
        <strong>Pagada</strong>
      </p>
      <ion-button shape="round" color="primary" expand="block" (click)="closeModal()">OK</ion-button>
    </ion-row>
  </ion-grid>
</ion-content>
