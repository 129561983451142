import { Component, Input } from '@angular/core';
import { Clipboard } from '@capacitor/clipboard';
import { ModalController } from '@ionic/angular';
import { Payment, PaymentMethod } from '@shared/models/payment.model';

@Component({
  selector: 'app-payment-confirmation',
  templateUrl: './payment-confirmation.component.html',
  styleUrls: ['./payment-confirmation.component.scss'],
})
export class PaymentConfirmationComponent {
  @Input() amount = 0;
  @Input() payment?: Payment;
  @Input() order?: any;
  @Input() method?: PaymentMethod;
  @Input() id = 9439438;

  constructor(private modalController: ModalController) {}

  closeModal() {
    this.modalController.dismiss();
  }

  async copyToClipboard() {
    await Clipboard.write({
      string: this.order?.charges?.data[0].payment_method?.clabe,
    });
  }
}
