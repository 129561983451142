import { format } from 'date-fns';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'time',
})
export class TimePipe implements PipeTransform {
  transform(value: Date | string | number, ...args: any[]): any {
    if (!value) return '';
    //value="2018-05-28 12:00:00";
    return format(new Date(value), 'dd/MM/yyyy HH:mm:ss');
  }
}
